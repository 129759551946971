import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAccount, UserInterface } from '@monsido/modules/models/api/interfaces/user.interface';
import { DialogService, LayoutModule, MonAvatarModule, TableModule, ToastService, TranslateModule, TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BackendAdminCustomersRepo } from 'app/services/api/backend_admin/backend-admin-customers-repo';
import { BackendAdminUserRepo } from 'app/services/api/backend_admin/backend-admin-user-repo.service';
import { SudoButtonComponent } from '@layout/buttons/sudo/sudo-button.component';
import { UIRouterModule } from '@uirouter/angular';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { FormBackendAdminUserComponent } from 'app/forms/backend_admin/user/form-backend-admin-user/form-backend-admin-user.component';

@Component({
    selector: 'mon-backend-admin-users-table',
    standalone: true,
    imports: [CommonModule, TableModule, MonAvatarModule, LayoutModule, SudoButtonComponent, UIRouterModule, PipesModule, ConfirmModule, TranslateModule],
    templateUrl: './backend-admin-users-table.component.html',
})
export class BackendAdminUsersTableComponent implements OnInit {
    @Input() users: UserInterface[];
    @Input() selectedUsers: UserInterface[];
    @Output() getPage: EventEmitter<void> = new EventEmitter();

    constructor (
        private toastService: ToastService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private backendAdminCustomersRepo: BackendAdminCustomersRepo,
        private backendAdminUserRepo: BackendAdminUserRepo,
    ) {}

    ngOnInit (): void {
        this.selectedUsers = this.selectedUsers || [];
    }

    onEditUser (user: UserInterface): void {
        if (!this.isEditable(user)) {
            // This is the backend_admin, so a simple warning should be ok
            this.toastService.warning(this.translateService.getString('This user is missing a customer agreement'));
        } else {
            const agreements = user.user_agreements.filter(function (agreement) {
                return agreement.account && agreement.account.type === 'Customer';
            });

            const dialogRef = this.dialogService.open(FormBackendAdminUserComponent, {
                defaultWrapper: false,
                classes: 'mon-dialog-size-sm',
                cb: () => {
                    this.getPage.emit();
                },
            });
            dialogRef.componentInstance.user = user;
            dialogRef.componentInstance.customerId = agreements[0].account.id;
        }
    }

    onDeleteUser (user: UserInterface): void {
        if (!this.isEditable(user)) {
            // This is the backend_admin, so a simple warning should be ok
            this.toastService.warning(this.translateService.getString('This user is missing a customer agreement'));
        } else {
            const agreements = user.user_agreements.filter(function (agreement) {
                return agreement.account && agreement.account.type === 'Customer';
            });

            this.backendAdminCustomersRepo.destroyUser(agreements[0].account.id, user.id).then(() => {
                this.toastService.success(this.translateService.getString('User deleted'));
                this.getPage.emit();
            }, () => {});
        }
    }

    onSudo (sudoPayload: { customerId: number, user: UserInterface }): void {
        const { customerId, user } = sudoPayload;
        if (!customerId || !user) {
            return;
        }
        this.backendAdminUserRepo.sudoUser(user.id, customerId).then(()=>{}, ()=>{});
    }

    resellerAgreement (user: UserInterface): UserAccount {
        if (user && Array.isArray(user.user_agreements)) {
            const user_agreement = user.user_agreements.find(function (agreement) {
                return agreement.account && agreement.account.type === 'Reseller';
            });

            if (user_agreement) {
                return user_agreement.account;
            }
            return null;
        } else {
            return null;
        }
    }

    isEditable (user: UserInterface): boolean {
        let agreements = user.user_agreements;
        if (Array.isArray(agreements) && agreements.length > 0) {
            agreements = agreements.filter((agreement) => {
                return agreement.account && agreement.account.type === 'Customer';
            });

            if (agreements.length > 0) {
                return true;
            }
        }
        return false;
    }

    countCustomers (user: UserInterface): number {
        if (user.user_agreements) {
            return user.user_agreements.reduce((num, agreement) => {
                if (agreement.account && agreement.account.type === 'Customer') {
                    num++;
                }
                return num;
            }, 0);
        } else {
            return 0;
        }
    }

    getFirstCustomerId (user: UserInterface): number {
        const agreement = user.user_agreements.find((ag) => {
            return ag.account && ag.account.type === 'Customer';
        });
        if (agreement) {
            return agreement.account.id;
        } else {
            return null;
        }
    }
}
