import { Component, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/core';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import {
    AccessibilityTypes,
    AdditionalInternalUrl,
    BackendResellerScans,
    SourceCodeExclude,
    SourceCodeExcludeModules,
    LoginTypes,
    LinkExclude,
    ReadabilityTest,
    RenderingService,
} from '@monsido/modules/endpoints/api/backend_admin/backend-scans.repo';
import { Scan } from '@monsido/modules/models/api/scan';
import { CHECKER_TABS } from '@monsido/pages/backend-admin/support/request-checker/request-checker.constant';
import { ParamService } from '@monsido/core/param/param.service';
import { SelectOption } from '@monsido/core/constants/select-options.constant';
import { DomainScanLogin } from 'types/domain';

type Excludes = {
    module: SourceCodeExcludeModules,
    selector: string,
}[]

@Component({
    selector: 'request-checker-component',
    templateUrl: 'request-checker.html',
    styleUrls: ['./request-checker.scss'],
})

export class RequestCheckerPageComponent implements OnInit {
    checkerTabs = CHECKER_TABS;
    activeTab: string;
    tabs: Record<string, string>[] = [];

    renderOptions: SelectOption[] = [];
    accessibilityOptions: SelectOption[] = [];
    internalUrlOptions: SelectOption[] = [];
    sourceCodeExcludeOptions: SelectOption[] = [];
    loginOptions: SelectOption[] = [];
    readabilityOptions: SelectOption[] = [];

    url: string;
    enableJavascript: boolean;
    jsRenderingService: RenderingService;
    accessibility: AccessibilityTypes;
    linkExcludes: LinkExclude[];
    internalUrls: AdditionalInternalUrl[];
    loginForm: DomainScanLogin = { type: LoginTypes.NONE };

    searching: boolean = false;
    scan: Scan;
    excludes: Excludes = [];

    readabilityTest: ReadabilityTest;

    constructor (
        private translateService: TranslateService,
        private $location: UrlService,
        private backendResellerScans: BackendResellerScans,
        private paramService: ParamService,
    ) {}

    async ngOnInit (): Promise<void> {
        this.setupOptions();

        this.url = this.$location.search()['url'] ? decodeURIComponent(this.$location.search()['url']) : undefined;
        this.enableJavascript = this.$location.search()['enable_javascript'];
        this.accessibility = this.$location.search()['accessibility'];
        this.jsRenderingService = this.$location.search()['js_render_service'];
        this.readabilityTest = this.$location.search()['readability_test'];


        try {
            this.loginForm = JSON.parse(decodeURIComponent(this.$location.search()['login'])) || { type: LoginTypes.NONE };
        } catch {
            this.loginForm = { type: LoginTypes.NONE };
        }

        try {
            const sourceCodeParam = this.$location.search()['source_code_excludes'];
            this.excludes = JSON.parse(decodeURIComponent(sourceCodeParam)) || [];
        } catch {
            this.excludes = [];
        }

        try {
            const linkExcludeParam = this.$location.search()['link_excludes'];
            this.linkExcludes = JSON.parse(decodeURIComponent(linkExcludeParam)) || [];
        } catch {
            this.linkExcludes = [];
        }


        try {
            const internalUrlParam = this.$location.search()['internal_urls'];
            this.internalUrls = JSON.parse(decodeURIComponent(internalUrlParam)) || [];
        } catch {
            this.internalUrls = [];
        }

        this.tabs = Object.keys(CHECKER_TABS).map((key: keyof typeof CHECKER_TABS) => {
            return {
                ...CHECKER_TABS[key],
                faIcon: CHECKER_TABS[key].icon,
            };
        });

        this.activeTab = 'base-info';
        if (this.url) {
            await this.search();
        }
    }

    setupOptions (): void {
        this.renderOptions = [
            {
                name: RenderingService.PUPPETEER,
                value: RenderingService.PUPPETEER,
            },
            {
                name: RenderingService.LEGACY,
                value: RenderingService.LEGACY,
            },
        ];

        this.accessibilityOptions = [
            {
                name: AccessibilityTypes.WCAG2A,
                value: AccessibilityTypes.WCAG2A,
            },
            {
                name: AccessibilityTypes.WCAG2AA,
                value: AccessibilityTypes.WCAG2AA,
            },
            {
                name: AccessibilityTypes.WCAG2AAA,
                value: AccessibilityTypes.WCAG2AAA,
            },
            {
                name: AccessibilityTypes.WCAG21A,
                value: AccessibilityTypes.WCAG21A,
            },
            {
                name: AccessibilityTypes.WCAG21AA,
                value: AccessibilityTypes.WCAG21AA,
            },
            {
                name: AccessibilityTypes.WCAG21AAA,
                value: AccessibilityTypes.WCAG21AAA,
            },
            {
                name: AccessibilityTypes.WCAG22A,
                value: AccessibilityTypes.WCAG22A,
            },
            {
                name: AccessibilityTypes.WCAG22AA,
                value: AccessibilityTypes.WCAG22AA,
            },
            {
                name: AccessibilityTypes.WCAG22AAA,
                value: AccessibilityTypes.WCAG22AAA,
            },
        ];

        this.sourceCodeExcludeOptions = [
            {
                name: SourceCodeExcludeModules.READABILITY,
                value: SourceCodeExcludeModules.READABILITY,
            },
            {
                name: SourceCodeExcludeModules.SPELLING,
                value: SourceCodeExcludeModules.SPELLING,
            },
        ];

        this.loginOptions = Object.keys(LoginTypes).map((key: keyof typeof LoginTypes) => {
            return {
                name: LoginTypes[key],
                value: LoginTypes[key],
            };
        });
        this.internalUrlOptions = [
            {
                value: 'contains',
                name: this.translateService.getString('Contains'),
            },
            {
                value: 'starts_with',
                name: this.translateService.getString('Starts with'),
            },
            {
                value: 'regex',
                name: this.translateService.getString('Regex'),
            },
        ];

        this.readabilityOptions = [
            {
                name: ReadabilityTest.ARI,
                value: ReadabilityTest.ARI,
            },
            {
                name: ReadabilityTest.COLEMAN_LIAU,
                value: ReadabilityTest.COLEMAN_LIAU,
            },
            {
                name: ReadabilityTest.FLESCH_KINCAID_GL,
                value: ReadabilityTest.FLESCH_KINCAID_GL,
            },
            {
                name: ReadabilityTest.FLESCH_KINCAID_RE,
                value: ReadabilityTest.FLESCH_KINCAID_RE,
            },
            {
                name: ReadabilityTest.GUNNING_FOG,
                value: ReadabilityTest.GUNNING_FOG,
            },
            {
                name: ReadabilityTest.LIX,
                value: ReadabilityTest.LIX,
            },
            {
                name: ReadabilityTest.SMOG,
                value: ReadabilityTest.SMOG,
            },
        ];
    }

    onEnableJSChange (): void {
        if (this.enableJavascript === false) {
            this.jsRenderingService = undefined;
            this.accessibility = undefined;
        }
    }

    addScanOption (scanOptionArr: Record<string, unknown>[], optionRule: Record<string, unknown>): void {
        scanOptionArr.push(optionRule);
    }

    removeScanOption (scanOptionArr: Record<string, unknown>[], index: number): void {
        scanOptionArr.splice(index, 1);
    }

    canSearch (): string {
        return this.url;
    }

    onTabChange (tab: string): void {
        this.activeTab = tab;
    }

    addSourceCodeExclude (): void {
        this.excludes.push({ selector: '', module: SourceCodeExcludeModules.READABILITY });
    }

    removeSourceCodeExclude (index: number): void {
        this.excludes.splice(index, 1);
    }

    cleanupLogin (login: DomainScanLogin): DomainScanLogin | undefined {
        const validLogin: string[] = Object.values(LoginTypes);
        if (login.type === LoginTypes.NONE || !validLogin.includes(login.type)) {
            return undefined;
        }

        const cleanedLogin = {
            type: login.type,
            [login.type]: login[login.type as keyof DomainScanLogin],
        };

        if (login.type === LoginTypes.FORM || login.type === LoginTypes.CUSTOM_MULTISTEP) {
            return {
                ...cleanedLogin,
                verify: login.verify,
            };
        }

        return cleanedLogin;
    }

    async search (): Promise<void> {
        this.scan = null;
        this.searching = true;

        this.paramService.setParams({
            url: this.url,
            enable_javascript: this.enableJavascript,
            js_render_service: this.jsRenderingService,
            accessibility: this.accessibility,
            readability_test: this.readabilityTest,
            source_code_excludes: this.excludes.length ? JSON.stringify(this.excludes) : undefined,
            login: JSON.stringify(this.cleanupLogin(this.loginForm)),
            link_excludes: JSON.stringify(this.linkExcludes),
            additional_internal_urls: JSON.stringify(this.internalUrls),
        });

        const sourceCodeExcludes: SourceCodeExclude[] = Object.keys(SourceCodeExcludeModules).map((key: keyof typeof SourceCodeExcludeModules) => {
            const excludes = this.excludes.filter(exclude => exclude.module === SourceCodeExcludeModules[key]);
            return {
                module: SourceCodeExcludeModules[key],
                rules: excludes.map((option) => ({ operator: 'css' as const, value: option.selector })),
            };
        })
            .filter((moduleExcludes) => moduleExcludes.rules.length > 0);

        this.backendResellerScans.scans({
            url: this.url,
            enable_javascript: this.enableJavascript,
            js_rendering_service: this.jsRenderingService,
            accessibility: this.accessibility,
            readability_test: this.readabilityTest,
            source_code_excludes: sourceCodeExcludes,
            login: this.cleanupLogin(this.loginForm),
            link_excludes: this.linkExcludes,
            additional_internal_urls: this.internalUrls,
        }).then((data: Scan) => {
            this.scan = data;
        })
            .finally(() => {
                this.searching = false;
                this.activeTab = 'base-info';
            });
    }
}
