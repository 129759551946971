import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Customer } from '@monsido/modules/models/api/customer';
import { Domain } from '@monsido/modules/models/api/domain';
import { TranslateReadabilityPipe } from '@monsido/core/pipes/translateReadability/translateReadability.pipe';
import { ControlContainer, NgForm } from '@angular/forms';
import { DialogService, TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { PageFixDomainSettingsComponent } from '@monsido/forms/backend_admin/page-fix-domain-settings/page-fix-domain-settings.component';
import { FormBackendAdminPageAssistComponent } from '@monsido/forms/backend_admin/page-assist/page-assist.component';
import { PageAssistSettings, PageCorrect } from 'types/domain';
import { BROWSER_SERVICE } from '@monsido/core/constants/defaults.constant';


interface AccessibilityOption {
    accessibility_guideline_id: string;
    name: string;
}

interface ReadabilityOption {
    readability_test_id: string;
    name: string;
}

interface ClarityOption {
    guideline_id: string;
    name: string;
}

@Component({
    selector: 'mon-form-domain-features',
    templateUrl: 'features.html',
    styleUrls: ['./features.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormDomainFeaturesComponent implements OnInit {
    @Input() monDomain: Domain;
    @Input() monCustomer: Customer;
    @Input() monForm: NgForm;
    @Input() monIsBackendAdmin: boolean = false;

    @ViewChild('domainFeatureFieldsForm') public form: NgForm;

    accessibilities: AccessibilityOption[] = [];
    readabilities: ReadabilityOption[] = [];
    clarityOptions: ClarityOption[] = [];
    translateReadabilityPipe: TranslateReadabilityPipe;

    constructor (private translateService: TranslateService, private dialogService: DialogService) {
        this.translateReadabilityPipe = new TranslateReadabilityPipe(this.translateService);
    }

    ngOnInit (): void {
        this.monDomain.features = {
            ...this.monDomain.features,
            statistics: this.monDomain.features.statistics || false,
            accessibility: this.monDomain.features.accessibility || null,
            readability_test: this.monDomain.features.readability_test || null,
            page_assist: this.monDomain.features.page_assist || false,
        };

        const showMobileGuidelines = this.monCustomer?.plan_traits?.mobile_guidelines && this.monCustomer.settings?.js_rendering_service === BROWSER_SERVICE.PUPPETEER;
        const isNewDomain = this.monDomain.id === undefined;
        const useNextVGuideline = isNewDomain || this.monDomain.features?.accessibility?.includes('v4');

        const guidelineMap = {
            'WCAG2-A': this.translateService.getString('WCAG 2.0 (Level A)'),
            'WCAG2-AA': this.translateService.getString('WCAG 2.0 (Level AA)'),
            'WCAG2-AAA': this.translateService.getString('WCAG 2.0 (Level AAA)'),
            'WCAG21-A': this.translateService.getString('WCAG 2.1 (Level A)'),
            'WCAG21-AA': this.translateService.getString('WCAG 2.1 (Level AA)'),
            'WCAG21-AAA': this.translateService.getString('WCAG 2.1 (Level AAA)'),
            'WCAG21-AA_mobile': this.translateService.getString('WCAG 2.1 (Level AA) Mobile'),
            'WCAG21-AAA_mobile': this.translateService.getString('WCAG 2.1 (Level AAA) Mobile'),
            'WCAG22-A': this.translateService.getString('WCAG 2.2 (Level A)'),
            'WCAG22-AA': this.translateService.getString('WCAG 2.2 (Level AA)'),
            'WCAG22-AAA': this.translateService.getString('WCAG 2.2 (Level AAA)'),
            'WCAG22-A_mobile': this.translateService.getString('WCAG 2.2 (Level A) Mobile'),
            'WCAG22-AA_mobile': this.translateService.getString('WCAG 2.2 (Level AA) Mobile'),
            'WCAG22-AAA_mobile': this.translateService.getString('WCAG 2.2 (Level AAA) Mobile'),
        };

        for (const [guideline, name] of Object.entries(guidelineMap)) {
            if (guideline.includes('mobile') && !showMobileGuidelines) {
                continue;
            }
            this.accessibilities.push(this.createGuidelineOption(guideline, name, useNextVGuideline));
        }

        this.readabilities = [
            {
                readability_test_id: 'flesch_kincaid_re',
                name:
                    this.translateReadabilityPipe.transform('flesch_kincaid_re') +
                    ' ' +
                    this.translateService.getString('Readability test'),
            },
            {
                readability_test_id: 'lix',
                name: this.translateReadabilityPipe.transform('lix') + ' ' + this.translateService.getString('Readability test'),
            },
        ];
        this.clarityOptions = [
            {
                guideline_id: 'WCAG2_0',
                name: this.translateService.getString('WCAG 2.0'),
            },
            {
                guideline_id: 'WCAG2_1',
                name: this.translateService.getString('WCAG 2.1'),
            },
        ];
    }

    openPageAssist (): void {
        const dialogRef = this.dialogService.open(FormBackendAdminPageAssistComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: (settings: PageAssistSettings) => {
                if (settings) {
                    this.monDomain.page_assist_settings = settings;
                }
            },
        });
        dialogRef.componentInstance.domain = this.monDomain;
        dialogRef.componentInstance.customer = this.monCustomer;
        dialogRef.componentInstance.saveToAPI = false;
    }

    openPageFix (): void {
        const dialogRef = this.dialogService.open(PageFixDomainSettingsComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: (settings: PageCorrect) => {
                if (settings) {
                    this.monDomain.settings.scripts.page_correct.admin_only = settings.admin_only;
                    this.monDomain.settings.scripts.page_correct.enabled_checks = settings.enabled_checks;
                }
            },
        });
        dialogRef.componentInstance.domain = this.monDomain;
        dialogRef.componentInstance.customer = this.monCustomer;
        dialogRef.componentInstance.saveToAPI = false;
    }

    createGuidelineOption (guideline: string, name: string, useNextVGuideline: boolean): AccessibilityOption {
        const suffix = useNextVGuideline ? '_v4' : '_v2';

        return {
            name,
            accessibility_guideline_id: guideline + suffix,
        };
    }
}
